import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useEffect, useMemo } from 'react'

import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import { Slider } from '@/components/Slider'
import Tooltip from '@/components/Tooltip'

import { numberWithSpace } from '@/utils/numberWithSpaces'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { useAdNetworksTypes } from '@/bus/adNetworksTypes'
import { useCalculateAwards } from '@/bus/calculateAwards'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

const MIN_VALUE = 30000

const DEFAULT_DATA = {
  type: 'yandexdirect',
  value: 80000
}

export const RewardCalculator: FC<DefaultComponentProps> = ({
  className = ''
}) => {
  const { t } = useTranslation()
  let { isAuthorized } = useUser()
  const { types, getTypes, setValues } = useAdNetworksTypes(DEFAULT_DATA)
  const { awards, setAwards, calculate } = useCalculateAwards()

  useEffect(() => {
    getTypes()
    // eslint-disable-next-line
  }, [])

  const TYPES: {
    [key: string]: {
      icon: string
      label: string
      summary?: string
    }
  } = useMemo(
    () =>
      t('reward-calculator:types', {
        returnObjects: true
      }),
    [t]
  )

  const handleChange = useCallback((value: number, type: string) => {
    setValues(
      (types = []) =>
        types?.map(item =>
          item.type === type ? { ...item, value: value } : item
        ) ?? []
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (calculate.progress) {
      return
    }

    const notEmptyTypes = types?.filter(({ value = 0 }) => value > 0) ?? []

    if (!notEmptyTypes.length) {
      setAwards(null)
      return
    }
    calculate({
      types: notEmptyTypes.map(({ type, value }) => ({
        adnetwork_type: type,
        budget_amount: value
      }))
    })
    // eslint-disable-next-line
  }, [types])

  const isMinValue = useMemo(
    () =>
      calculate.finished && (awards?.summary?.budget_amount ?? 0) < MIN_VALUE,
    [calculate.finished, awards]
  )

  return !getTypes.finished || !types ? null : (
    <div className={clsx(styles['section'], className)}>
      <div className={styles['section__inner']}>
        <div className={styles['section__header']}>
          <Heading className={styles['section__heading']} level={2}>
            {t('reward-calculator:heading')}
          </Heading>
        </div>
        <div className={styles['section__content']}>
          <div className={styles['section__content-column']}>
            <div className={styles['section__content-scroll']}>
              <div className={styles['section__content-scroll-inner']}>
                <div className={styles['section__list']}>
                  {types.map(({ type, value = 0 }) => (
                    <div key={type} className={styles['section__list-item']}>
                      <div className={styles['section__list-content']}>
                        <div className={styles['section__list-heading']}>
                          <div className={styles['section__list-heading-icon']}>
                            <Icon
                              className={
                                styles['section__list-heading-icon-svg']
                              }
                              name={`reward-calculator/${TYPES[type].icon}`}
                            />
                          </div>
                          <div
                            className={styles['section__list-heading-label']}
                          >
                            {TYPES[type].label}
                          </div>
                        </div>
                        <div className={styles['section__list-text1']}>
                          {t('reward-calculator:planned_turnover')}
                        </div>
                        {TYPES[type]?.summary && (
                          <div className={styles['section__list-text2']}>
                            {TYPES[type].summary}
                          </div>
                        )}
                      </div>
                      <div className={styles['section__list-slider']}>
                        <Slider
                          onChange={value => handleChange(value, type)}
                          value={value}
                          min={0}
                          max={1000000}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={styles['section__content-column']}>
            <div className={styles['section__plans']}>
              <div className={styles['section__plans-turnover']}>
                {t('reward-calculator:planned_turnover_for_month')}{' '}
                <Tooltip
                  summary={t('reward-calculator:info_vat')}
                  className={styles['scroll-table__info']}
                >
                  <Icon name={'status/information/24'} color={'#fff'} />
                </Tooltip>
              </div>
              <div className={styles['section__plans-total']}>
                {t('reward-calculator:money', {
                  money: numberWithSpace(awards?.summary?.budget_amount ?? 0)
                })}
              </div>
              <div className={styles['section__plans-turnover']}>
                {t('reward-calculator:monthly_reward')}{' '}
                <Tooltip
                  summary={t('reward-calculator:info_base_awards')}
                  className={styles['scroll-table__info']}
                >
                  <Icon name={'status/information/24'} color={'#fff'} />
                </Tooltip>
              </div>
              <div className={styles['section__plans-total']}>
                {t('reward-calculator:money', {
                  money: isMinValue
                    ? 0
                    : numberWithSpace(awards?.summary?.award_amount ?? 0)
                })}
              </div>
              {isMinValue && (
                <div className={styles['section__min-value-error']}>
                  <div>
                    <Icon name={'status/warning/24'} color={'#F04C41'} />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('reward-calculator:min_value_error', {
                        value: numberWithSpace(MIN_VALUE, '&nbsp;')
                      })
                    }}
                  />
                </div>
              )}
              <div className={styles['section__plans-summary']}>
                {t('reward-calculator:summary')}
              </div>
            </div>
          </div>
        </div>

        <div className={styles['section__footer']}>
          <Button
            title={t('forms:buttons.signup') as string}
            as={Link}
            href={
              isAuthorized
                ? {
                    pathname: routes.SERVICES,
                    external: false
                  }
                : routes.REGISTER
            }
            size={'l'}
            className={styles['section__button']}
            before={<Icon name={'arrows/right/24'} />}
          >
            {t(
              isAuthorized ? 'forms:buttons.services' : 'forms:buttons.signup'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
